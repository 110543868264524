import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';
import { store } from 'store';
import { setWebsocketData } from 'store/slices/websoket';

class Websocket {
  readonly instance;

  constructor(url: string, pathUrl: string, token: string) {
    this.instance = new HubConnectionBuilder()
      .withUrl(`${url}${pathUrl}`, {
        skipNegotiation: true,
        accessTokenFactory: () => token,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          if (retryContext.elapsedMilliseconds < 60000) {
            return Math.random() * 10000;
          }
          return null;
        },
      })
      .build();

    this.instance.start().then(() => {
      console.log('SignalR connection established');
    }).catch((error) => {
      console.log('SignalR connection error:', error);
    });

    this.instance.on('ReceiveMessage', (message) => {
      store.dispatch(setWebsocketData(message));
    });
  }

  public closeWebSocket = () => {
    if (this.instance) {
      this.instance.stop();
    }
  };
}

export default Websocket;
