import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  PaymentIntentDTO,
  PayoutFeePayloadSchema,
  PayoutPayoutFeeDTO,
  PayoutQuoteDTO,
  PayoutQuoteRequestPayloadSchema,
} from 'views/types';

const baseUrl = '/api/v1/';
// const baseUrl = 'https://payment.cryptopay-dev.space-app.io/api/v1/';
const tags = ['Deposit'];

export const payoutApi = createApi({
  reducerPath: 'payoutApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  tagTypes: tags,
  endpoints: (builder) => ({
    postPayoutIntent: builder.mutation<PaymentIntentDTO,
            {authToken: string}>({
              invalidatesTags: tags,
              query: ({ authToken }) => ({
                url: 'payout-intents',
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${authToken}`,
                },
              }),
            }),
    postPayoutIntentQuote: builder.mutation<PayoutQuoteDTO,
            {payoutIntentQuoteData: PayoutQuoteRequestPayloadSchema} & {authToken: string}
            >({
              invalidatesTags: tags,
              query: ({ payoutIntentQuoteData, authToken }) => ({
                url: 'payout-intents/quote-request',
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${authToken}`,
                },
                body: payoutIntentQuoteData,
              }),
            }),
    postPayoutFee: builder.mutation<PayoutPayoutFeeDTO,
        {payoutFeeData: PayoutFeePayloadSchema} & {authToken: string}
        >({
          invalidatesTags: tags,
          query: ({ payoutFeeData, authToken }) => ({
            url: 'payout-intents/fee',
            method: 'POST',
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
            body: payoutFeeData,
          }),
        }),
  }),
});

export const {
  usePostPayoutFeeMutation,
  usePostPayoutIntentMutation,
  usePostPayoutIntentQuoteMutation,
} = payoutApi;
