import { useEffect, Suspense, lazy } from 'react';

import { useAppDispatch } from 'store/hooks';
import { setLocalization, setGlobalParams } from 'store/slices/global';
import { useGetLocalizationQuery } from 'store/slices/global/services/globalApi';
import { formatInitialQueryParams, getUserLanguage, localStorageSet } from 'utils';
import Websocket from 'services/Websocket';
import { useGetPaymentGatewayQuery } from 'store/slices/deposit/services/depositApi';
import { setPaymentGatewayAccess } from 'store/slices/deposit';

import 'normalize.css';

const MainApp = lazy(() => import('components/MainApp'));

enum WsFlowTypes {
  'deposit' = 'deposit',
  'payout' = 'payout',
}

const App = () => {
  const dispatch = useAppDispatch();
  const initialQueryParams = formatInitialQueryParams(window.location.search);
  const { token: authToken, languageIso2, pageMode } = initialQueryParams;
  const websocketUrl = window.location.origin;
  const locationPathname = window.location.pathname.substring(1);
  const wsPathUrl = locationPathname === WsFlowTypes.deposit ? '/hubs/paymentIntent' : '/hubs/payoutIntent';
  const userLanguages = languageIso2 || getUserLanguage();

  const {
    isLoading: isLocalizationLoading,
    data: dataLocalization = {},
  } = useGetLocalizationQuery({
    languageIso2: userLanguages, authToken,
  });

  const {
    isLoading: isPaymentIntentsLoading,
    data: dataPaymentIntents = {},
  } = useGetPaymentGatewayQuery({
    authToken,
  });

  useEffect(() => {
    const websocketPaymentIntentInstance = new Websocket(websocketUrl, wsPathUrl, authToken);
    localStorageSet('authToken', initialQueryParams.token);
    const queryData = {
      ...initialQueryParams,
      currentLanguage: userLanguages,
    };
    dispatch(setGlobalParams(queryData));

    return () => {
      websocketPaymentIntentInstance.closeWebSocket();
    };
  }, []);
  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (rootElement && pageMode) {
      rootElement.classList.add('isPageMode');
    }
  }, []);

  useEffect(() => {
    if (dataLocalization?.resources && Object.keys(dataLocalization.resources).length) {
      dispatch(setLocalization({
        resources: dataLocalization.resources, languageIso2: userLanguages,
      }));
    }
  }, [isLocalizationLoading]);

  useEffect(() => {
    dispatch(setPaymentGatewayAccess(dataPaymentIntents));
  }, [isPaymentIntentsLoading]);

  return (
    <Suspense fallback="...">
      <MainApp pageMode={pageMode} />
    </Suspense>
  );
};

export default App;
