import { createSlice } from '@reduxjs/toolkit';
import {
  EmptyObject,
  PayoutIntentReducerSchema,
} from 'views/types';

interface PayoutIntentInitialState {
  payoutIntent: PayoutIntentReducerSchema | EmptyObject;
}

const initialState: PayoutIntentInitialState = {
  payoutIntent: {},
};

export const payoutSlice = createSlice({
  name: 'payout',
  initialState,
  reducers: {
    setPayoutIntentData(state, action) {
      return {
        ...state,
        payoutIntent: {
          ...state.payoutIntent,
          ...action.payload,
          additionalAddress: action.payload.additionalAddress || null,
          additionalAddressType: action.payload.additionalAddressType || null,
        },
      };
    },
    updatePayoutIntentData(state, action) {
      return {
        ...state,
        payoutIntent: {
          ...state.payoutIntent,
          ...action.payload,
        },
      };
    },
  },
});

export const { setPayoutIntentData, updatePayoutIntentData } = payoutSlice.actions;
export default payoutSlice.reducer;
