/* tslint:disable:no-empty */
export const localStorageSet = (key: string | null | undefined, value: string) => {
  if (!key) return;

  try {
    localStorage.setItem(key, value);
  } catch {}
};

export const localStorageGet = (key: string | null | undefined) => {
  if (!key) return null;

  try {
    return localStorage.getItem(key);
  } catch {
    return null;
  }
};

export const localStorageRemove = (key: string | null | undefined) => {
  if (!key) return;

  try {
    localStorage.removeItem(key);
  } catch {}
};

export const getStorageAuthToken = () => localStorage.getItem('authToken') || '';
