import { configureStore } from '@reduxjs/toolkit';

import { depositReducer, globalReducer, payoutReducer, websocketReducer } from './slices';
import { depositApi } from './slices/deposit/services/depositApi';
import { payoutApi } from './slices/payout/services/payoutApi';
import { globalApi } from './slices/global/services/globalApi';

export const store = configureStore({
  reducer: {
    websocket: websocketReducer,
    deposit: depositReducer,
    [depositApi.reducerPath]: depositApi.reducer,
    payout: payoutReducer,
    [payoutApi.reducerPath]: payoutApi.reducer,
    global: globalReducer,
    [globalApi.reducerPath]: globalApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([depositApi.middleware,
    payoutApi.middleware,
    globalApi.middleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
