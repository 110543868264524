export const copyToClipboard = (id: string) => {
  const r = document.createRange();
  const selector = document.getElementById(id);
  if (selector) {
    r.selectNode(selector);
    window.getSelection()?.removeAllRanges();
    window.getSelection()?.addRange(r);
    try {
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      return true;
    } catch (err) {
      return false;
    }
  } else {
    return false;
  }
};

export const hideSuccessCopyTimeout = (handleHide: (value: boolean) => void) => setTimeout(() => {
  handleHide(false);
}, 2000);
