import { createSlice } from '@reduxjs/toolkit';
import { FlatStringObject } from 'views/types';

interface WebsocketInitialState {
  isConnected: boolean;
  wsData: {
    amount: number;
    currency: string;
    destinationAddress: string;
    destinationTag: string | null;
    paymentIntentStatus: string;
    payoutIntentStatus: string;
    payoutIntentFailedReason: string | null;
    paymentIntentFailedReason: FlatStringObject;
  } | null ;
}

const initialState: WebsocketInitialState = {
  isConnected: false,
  wsData: null,
};

const websocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    setConnected: (state, action) => ({
      ...state,
      isConnected: action.payload,
    }),
    setWebsocketData: (state, action) => ({
      ...state,
      wsData: action.payload,
    }),
  },
});

export const { setConnected, setWebsocketData } = websocketSlice.actions;

export default websocketSlice.reducer;
