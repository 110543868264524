import qs from 'query-string';
import { initialQueryParamsSchema } from 'views/types';

const parseStringEmpty = (v: unknown): string => (typeof v === 'string' ? v : '');
const parseStringBolean = (v: unknown): boolean => (v === 'true');

export const formatInitialQueryParams = (query: string): initialQueryParamsSchema => {
  const {
    currencyTo,
    amountTo,
    token,
    currencyFrom,
    amountFrom,
    paymentIntentId,
    merchantId,
    languageIso2,
    companyName,
    pageMode,
  } = qs.parse(query);

  return {
    currencyTo: parseStringEmpty(currencyTo),
    companyName: parseStringEmpty(companyName),
    pageMode: parseStringBolean(pageMode),
    currencyFrom: parseStringEmpty(currencyFrom),
    amountFrom: parseStringEmpty(amountFrom),
    amountTo: parseStringEmpty(amountTo),
    token: parseStringEmpty(token),
    paymentIntentId: parseStringEmpty(paymentIntentId),
    merchantId: parseStringEmpty(merchantId),
    languageIso2: parseStringEmpty(languageIso2),
  };
};
