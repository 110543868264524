type TPushEvent = {
    event?: string;
    paymentIntentId: string;
    merchantId: string;
    paymentIntentStatus?: string;
};

type TDataLayer = {
    push: (pushEvent: TPushEvent) => void;
};

declare global {
    interface Window {
        dataLayer: TDataLayer;
    }
}

if (!window.dataLayer) {
  window.dataLayer = {} as TDataLayer;
}

const DataLayer = (): TDataLayer => ({
  push: (props:TPushEvent) => {
    const dataToSend = {
      ...props,
    };
    window.dataLayer.push(dataToSend);
  },
});

export const dataLayer: TDataLayer = DataLayer();
